<template>
  <div>
    <!-- pic8680EE91CB5AA8252C83AF668AA411BF.jpg -->
    <img class="img-large rounded" src="">
    <p>
      With four pods safely on Earth and thousands of fans busy translating the data, the attention of the world turns to the final three pods. The first of these is believed to be landing in Berlin, the capital of Germany. And according to the scientists monitoring the pod signals, we should see it arrive very soon.
    </p>
    <p>
      "It is vital that people keep finding these pods," said Dr Nigel Ponsford-Stand from the Bradford Rant Institute. "There are four letters hidden in each of the data. We believe that when all the letters have been found they will form a very important message from whoever is sending the pods."
    </p>
    <p>
      To see how you can find a pod and to help decode the data, visit
      <a href="http://www.bradfordrant.org/">www.Bradfordrant.org</a>.
    </p>
  </div>
</template>
